import './css/style.css';
import NavBar from './comp/NavBar';
import Body from './comp/Body';

function App() {
  return (
    <div className="app">
      <NavBar />
      <Body />
    </div>
  );
}

export default App;
