import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import PortfolioCard from "../comp/PortfolioCard";
import cory_png from "../img/cory.png";

export default function Contact(props: any) {
    return (
        <>
            <h1>This page is under construction.</h1>
            <h6>...</h6>
            <h3>Check back soon for contact information.</h3>
        </>
    );
}